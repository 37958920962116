
import {
  createPlotsSheet,
  createBuildingsSheet,
  createUnitsSheet,
  createFacilitiesSheet,
  createCondosSheet,
  writeSheetsToXSLXAndSave,
} from "~/helpers/download-helpers.js";

import SortableTablColumnToggleButton from "~/components/SortableTable/SortableTablColumnToggleButton.vue";

// import CoAssessmentTable from "../CoAssessmentTable.vue";
import CadastralsTable from "../CadastralsTable.vue";
import BuildingsTable from "../BuildingsTable.vue";
import UnitsTable from "../UnitsTable.vue";
import TechnicalFacilitiesTable from "../TechnicalFacilitiesTable.vue";
import CondosTable from "../CondosTable.vue";
import PostAddressTable from "../PostAddressTable.vue";

import { getSelectedTabFromUrl } from "~/plugins/common-fns.js";
import PostAddressesForPlotNumbers from "~/graphql/Property/PostAddressesForPlotNumbers.gql";
import { getPropertyCondosByBfeNumberQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import { calculateBuildingArea, calculateRegisteredArea } from "~/helpers/area-calculation-helpers.js";
import { getUsageBreakdownByArea } from "~/helpers/usage-helpers.js";

export default {
  title() {
    return `${this.$t("MENU_BUILDINGS")}`;
  },

  components: {
    // CoAssessmentTable,
    CadastralsTable,
    BuildingsTable,
    UnitsTable,
    TechnicalFacilitiesTable,
    CondosTable,
    PostAddressTable,
    SortableTablColumnToggleButton,
  },

  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalActiveTable: "",
      active: getSelectedTabFromUrl(this),
    };
  },

  computed: {
    totalPlotArea: function () {
      return calculateRegisteredArea(this.property);
    },

    totalBuildingArea: function () {
      return calculateBuildingArea(this.property);
    },

    usageBreakdown: function () {
      const maxUsageTypes = 4;
      const usage = getUsageBreakdownByArea(this.property, maxUsageTypes, this.$t("USAGE_TYPE_OTHER"));
      return {
        series: [
          {
            data: Object.values(usage).map((u) => {
              return {
                x: u.usageText,
                y: u.usageArea,
              };
            }),
          },
        ],
      };
    },
  },

  watch: {
    "$route.query.activeTab": function (newValue) {
      this.active = newValue;
    },
  },

  apollo: {
    condos: getPropertyCondosByBfeNumberQuery,
    addressesForPlotNumbers: {
      query: PostAddressesForPlotNumbers,
      variables() {
        return {
          input: this.property.plots?.map((x) => x.plotNumber),
        };
      },
      skip() {
        return !this.property;
      },
    },
  },

  methods: {
    setModalActiveTable(component) {
      this.modalActiveTable = component;
    },

    download() {
      const plots = createPlotsSheet(this.property.plots, this.$i18n.locale);
      const buildings = createBuildingsSheet(this.property.buildings, this.$i18n.locale);
      const units = createUnitsSheet(this.property.units, this.$i18n.locale);
      const facilities = createFacilitiesSheet(this.property.technicalFacilities, this.$i18n.locale);
      const condos = createCondosSheet(this.condos, this.$i18n.locale);
      writeSheetsToXSLXAndSave([plots, buildings, units, facilities, condos], "Download");
    },

    selectTabAndLogAmplitudeEvent(id) {
      this.$router.push({
        name: "explore-property-id-buildings",
        params: this.$route.params,
        query: {
          activeTab: id,
        },
      });

      this.$amplitude.track({
        event_type: `View buildings`,
        event_properties: {
          tabIndex: id,
        },
      });
    },
  },
};
